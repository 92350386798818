body{
  padding-bottom: 4.5rem;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.list-group a :hover{
  color: #8665e0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-decoration-none{
  text-decoration: none !important;
}

.border-gray{
  border-color: #e9e9e9;
}

/* estilos animacion */
/* This changes all the animations globally */
:root {
  --animate-duration: 800ms;
  --animate-delay: 0.9s;
}

.border-legis{
  border: solid 1px #8665e0
}
.border-bottom-legis{
  border-bottom: solid 1px rgba(134, 101, 224, .25);
}
.border-top-legis{
  border-top: solid 1px rgba(134, 101, 224, .25);
}

.btn-legis{
  background-color: #8665e0 !important;
  border: none !important;
}

.bg-legis-light{
  background-color: #fafafa !important;
}

.bg-legis-default{
  background-color: #8665e0 !important;
}

footer.footer {
  position: fixed;
  height: 4rem;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  padding: .5rem;
  z-index: 1000;
}

footer.footer-login{
  position: fixed;
  height: auto;
  bottom: 0;
  width: 100%;
}

.container-credencial {
  height: 490px;
  width: 300px;
  position: relative;
  -webkit-perspective: 800px;
  -ms-perspective: 800px;
  perspective: 800px;
  border-radius: 4px;
  margin: auto;
}

.credencial {
  width: 100%;
  height: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 0.8s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 15px;
  cursor: pointer;
}

.credencial > div {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
  border-radius: 15px;
}

.credencial .credencial-back {
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.credencial.rotate {
  transform: rotateY(180deg);
}